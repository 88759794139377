import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { useStateContext } from "../context";
import { useMutation, useQueryClient } from "react-query";
import { logoutUserFn } from "../api/authApi";
import { AccountCircle, Menu as MenuIcon } from "@mui/icons-material";
import * as React from "react";
import { UserType } from "../api/types";

function Header() {
  const navigate = useNavigate();
  const stateContext = useStateContext();
  const user = stateContext.state.authUser;

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const queryClient = useQueryClient();
  const { mutate: logoutUser, isLoading } = useMutation(
    async () => logoutUserFn,
    {
      onSuccess: (data) => {
        stateContext.dispatch({ type: "RESET_USER", payload: null });
        queryClient.clear();
        navigate("/login");
      },
      onError: (error: any) => {
        if (Array.isArray(error.response.data.error)) {
          error.data.error.forEach((el: any) => {
            toast.error(el.message, {
              position: "top-right",
            });
          });
        } else {
          toast.error(error.response.data.message, {
            position: "top-right",
          });
        }
      },
    }
  );

  const handleLogout = async () => {
    logoutUser();
    handleCloseNavMenu();
  };

  const handleSettings = async () => {
    handleCloseNavMenu();
    navigate("/settings");
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const pages: Array<{ key: string; name: string; hide?: boolean }> =
    user?.type === UserType.SUPER_ADMIN
      ? [
          {
            key: "admin/organizations",
            name: "Organizations",
          },
          {
            key: "admin/users",
            name: "Manage Admins",
          },
          {
            key: "admin/configuration",
            name: "Global Configuration",
          },
        ]
      : [
          {
            key: "patient-profiles",
            name: "Patient Profiles",
          },
          {
            key: "users",
            name: "Manage Users",
          },
          {
            key: "configuration",
            name: "Configuration",
            hide: user?.type !== UserType.ORG_ADMIN,
          },
        ];

  const settings: Array<{ key: string; name: string; handler?: () => void }> = [
    {
      key: "settings",
      name: "Settings",
      handler: handleSettings,
    },
    {
      key: "logout",
      name: "Logout",
      handler: handleLogout,
    },
  ];

  return (
    <>
      <AppBar
        position="static"
        color={user?.type === UserType.SUPER_ADMIN ? "secondary" : "primary"}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              onClick={() => navigate("/")}
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              CI-Tuner
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={!!anchorElNav}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.key} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              variant="h5"
              noWrap
              component="a"
              onClick={() => navigate("/")}
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              CI_Tuner
            </Typography>
            {user && (
              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                {pages.map((page) =>
                  !page.hide ? (
                    <Button
                      key={page.key}
                      onClick={() => navigate(`/${page.key}`)}
                      sx={{ my: 2, mt: 2.75, color: "white", display: "block" }}
                    >
                      {page.name}
                    </Button>
                  ) : (
                    <div key={page.key}></div>
                  )
                )}
              </Box>
            )}
            <Box sx={{ flexGrow: 0 }}>
              {!user && (
                <>
                  <LoadingButton
                    type="submit"
                    color="secondary"
                    variant="contained"
                    fullWidth
                    sx={{ mt: 3, mb: 2 }}
                    loading={isLoading}
                    onClick={() => navigate("/login")}
                  >
                    Login
                  </LoadingButton>
                </>
              )}
              {user && (
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ mr: 1, mt: 1.5 }}>
                    {user?.type === UserType.SUPER_ADMIN ? (
                      <>{"Super Administrator"}</>
                    ) : (
                      <>
                        {"Organization: "}
                        {user.organization?.name || ""}
                      </>
                    )}
                  </Typography>
                  <Tooltip title="Open settings">
                    <IconButton
                      onClick={handleOpenUserMenu}
                      size="large"
                      color="inherit"
                    >
                      <AccountCircle />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={!!anchorElUser}
                    onClose={handleCloseUserMenu}
                  >
                    {settings.map((setting) => (
                      <MenuItem
                        key={setting.key}
                        onClick={setting.handler || handleCloseUserMenu}
                      >
                        <Typography textAlign="center">
                          {setting.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}

export default Header;
