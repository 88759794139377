import { createTheme } from "@mui/material/styles";
import { green } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1b76bb",
    },
    secondary: {
      main: green["700"],
    },
  },
});

export default theme;
