import axios from "axios";
import { LoginInput } from "../pages/SignIn";
import { GenericResponse, ILoginResponse, IUserResponse } from "./types";
const BASE_URL = process.env.REACT_APP_API_SERVER || "http://localhost:3333";

export const authApi = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

authApi.defaults.headers.common["Content-Type"] = "application/json";

// TODO: Change this to refresh? (below code uses expired access token to get a new one...)
export const refreshAccessTokenFn = async () => {
  const response = await authApi.get<ILoginResponse>("login");
  return response.data;
};

authApi.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const errMessage = error.response.data.message || error.response.data;
    if (errMessage.includes("User not logged in.") && !originalRequest._retry) {
      originalRequest._retry = true;
      return authApi(originalRequest);
    } else if (errMessage.includes("User not logged in.")) {
      window.dispatchEvent(new Event("RESET_USER"));
    }
    return Promise.reject(error);
  }
);

export const loginUserFn = async (user: LoginInput) => {
  const response = await authApi.post<ILoginResponse>("login", user);
  return response.data;
};

export const logoutUserFn = async () => {
  const response = await authApi.get<GenericResponse>("logout");
  return response.data;
};

export const getMeFn = async () => {
  const response = await authApi.get<IUserResponse>("users/me");
  return response.data;
};
