import { useQuery } from "react-query";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getMeFn } from "../api/authApi";
import { UserType } from "../api/types";
import { useStateContext } from "../context";
import FullScreenLoader from "./FullScreenLoader";
import Layout from "./layout";

const RequireUser = ({
  allowedUserTypes,
  outlet = false,
}: {
  allowedUserTypes: Array<UserType>;
  outlet?: boolean;
}) => {
  const location = useLocation();
  const stateContext = useStateContext();

  const {
    isLoading,
    isFetching,
    data: user,
  } = useQuery(["authUser"], () => getMeFn(), {
    enabled: !!stateContext.state.token,
    retry: 1,
    onSuccess: (data) => {
      stateContext.dispatch({ type: "SET_USER", payload: data });
    },
  });

  const loading = isLoading || isFetching;

  if (loading) {
    return <FullScreenLoader />;
  }

  return user && allowedUserTypes.includes(user?.type) ? (
    outlet ? (
      <Outlet />
    ) : (
      <Layout />
    )
  ) : user ? (
    <Navigate
      to={user?.type === UserType.SUPER_ADMIN ? "/admin/organizations" : "/"}
      state={{ from: location }}
      replace
    />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireUser;
