import { useQuery, useQueryClient } from "react-query";
import { getMeFn } from "../api/authApi";
import { useStateContext } from "../context";
import FullScreenLoader from "../components/FullScreenLoader";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UserType } from "../api/types";

type AuthMiddlewareProps = {
  token: string | null;
  children: React.ReactElement;
};

const AuthMiddleware: React.FC<AuthMiddlewareProps> = ({ token, children }) => {
  const stateContext = useStateContext();

  useEffect(() => {
    if (token) {
      stateContext.dispatch({ type: "SET_TOKEN", payload: token });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  window.addEventListener("RESET_USER", () => {
    stateContext.dispatch({ type: "RESET_USER", payload: null });
    queryClient.clear();
    toast.error("Your login has expired. Please sign-in again.", {
      position: "top-right",
      toastId: "login-expired",
    });
    navigate("/login");
  });

  const query = useQuery(["authUser"], () => getMeFn(), {
    enabled: !!stateContext.state.token,
    onSuccess: (data) => {
      stateContext.dispatch({ type: "SET_USER", payload: data });
      navigate(
        data.type === UserType.SUPER_ADMIN ? "/admin/organizations" : "/"
      );
    },
  });

  if (query.isLoading && token) {
    return <FullScreenLoader />;
  }

  return children;
};

export default AuthMiddleware;
