// React
import * as React from "react";

// Global Fonts
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

// Global Styles
import "react-toastify/dist/ReactToastify.css";

// Router
import routes from "./routes";
import { useRoutes } from "react-router-dom";

// Components
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
    {"Copyright © "}
      {new Date().getFullYear()}
      {" Objective Hearing."}
    </Typography>
  );
}

function App() {
  const content = useRoutes(routes);

  return (
    <>
      {content}
      <Box sx={{ my: 4 }}>
        <Copyright />
      </Box>
    </>
  );
}

export default App;
