import { GridFilterModel, GridSortItem } from "@mui/x-data-grid";

export interface IUser {
  id: number;
  organization_id: number;
  organization?: IOrganization;
  email: string;
  created_at: string;
  updated_at: string;
  type: UserType;
}

export interface GenericResponse {
  status: string;
  message: string;
}

export interface ILoginResponse {
  type: string;
  token: string;
  expires_at: string;
}

export interface IUserResponse {
  id: number;
  organization_id: number;
  organization?: IOrganization;
  email: string;
  created_at: string;
  updated_at: string;
  type: UserType;
}

export enum UserType {
  SUPER_ADMIN = "SUPER_ADMIN",
  ORG_ADMIN = "ORG_ADMIN",
  NORMAL = "NORMAL",
  DISABLED = "DISABLED",
}

export enum CIManufacturerType {
  MED_EL = "MED_EL",
  COCHLEAR = "COCHLEAR",
}

export enum PatientProfileStatusType {
  BEHAVIORAL_INPUTS = "BEHAVIORAL_INPUTS",
  UPLOAD_FILE_ONE = "UPLOAD_FILE_ONE",
  UPLOAD_FILE_TWO = "UPLOAD_FILE_TWO",
  COMPLETE = "COMPLETE",
}

export enum PatientProfileFileStepType {
  FILE_ONE = "FILE_ONE",
  FILE_TWO = "FILE_TWO",
}

export interface IPaginationResponse<T> {
  meta: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    first_page: number;
    first_page_url: string;
    last_page_url: string;
    next_page_url: string | null;
    previous_page_url: string | null;
  };
  data: Array<T>;
}

export interface IQueryOptions {
  sortModel?: GridSortItem | null;
  filterModel?: GridFilterModel | undefined;
  page?: number;
}

export interface IOrganization {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface IPatientProfile {
  id: number;
  patient_id: string;
  iem: boolean;
  reliable_feedback: boolean;
  ci_manufacturer: CIManufacturerType;
  status: PatientProfileStatusType;
  created_by: number;
  organization_id: number;
  enabled_electrodes: Array<number>;
  behavioral_inputs?: Array<PatientProfileBehavioralInputEntry>;
  version: number;
  created_at: string;
  updated_at: string;
}

export type PatientProfileBehavioralInputEntry = {
  electrode: number;
  pulse_width: number;
  pw: number;
  mcl: number;
  bdt: number;
};

export interface IPatientProfileFile {
  id: number;
  name: string;
  path: string;
  mime_type: string;
  step: PatientProfileFileStepType;
  created_at: string;
  updated_at: string;
}

export interface IOrganizationConfiguration {
  am_key_in_custom_sound: string;
}

export interface IGlobalConfiguration {
  masker_cl_offset: number;
  max_pulse_width_to_test_reliable_feedback: number;
  max_pulse_width_to_test_not_reliable_feedback: number;
  proberate_iem: number;
  proberate_noiem: number;
  fwd_masking_mpi: number;
  min_mpi_4_rrf: number;
}

export type IPatientProfileCompleteData = Array<{
  id: number;
  electrode: number;
  t_level: number;
  c_level: number;
  ecap_threshold: number;
  notes: string;
  pulse_rate: number;
  pulse_width: number;
  status: string;
}>;

export type IPatientProfileHistoryEntry = {
  id: number;
  patient_profile_id: number;
  created_at: string;
  updated_at: string;
  calculations: IPatientProfileCompleteData;
};

export const AllElectrodesArray = [
  22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1,
];

export const PulseWidthOptionsArray = [25, 37, 50, 62, 75, 88, 100];
