import { Suspense, lazy } from "react";
import type { RouteObject } from "react-router-dom";
import { UserType } from "./api/types";
import FullScreenLoader from "./components/FullScreenLoader";
import Layout from "./components/layout";
import RequireUser from "./components/requireUser";

const Loadable =
  (Component: React.ComponentType<any>) => (props: JSX.IntrinsicAttributes) =>
    (
      <Suspense fallback={<FullScreenLoader />}>
        <Component {...props} />
      </Suspense>
    );

// Global
const SignInPage = Loadable(lazy(() => import("./pages/SignIn")));

// Organizations
const OrganizationConfiguration = Loadable(
  lazy(() => import("./pages/Configuration"))
);

// Users
const Users = Loadable(lazy(() => import("./pages/Users")));
const AddUser = Loadable(lazy(() => import("./pages/Users/Add")));
const ShowUser = Loadable(lazy(() => import("./pages/Users/Show")));
const UpdateUserSettings = Loadable(lazy(() => import("./pages/Settings")));

// Patient Profiles
const PatientProfiles = Loadable(lazy(() => import("./pages/PatientProfiles")));
const AddPatientProfile = Loadable(
  lazy(() => import("./pages/PatientProfiles/Add"))
);
const ShowPatientProfile = Loadable(
  lazy(() => import("./pages/PatientProfiles/Show"))
);
const ShowPatientProfilePreviousCalculation = Loadable(
  lazy(
    () =>
      import(
        "./pages/PatientProfiles/Show/StepComplete/ShowPreviousCalculation"
      )
  )
);

// Super Admin
const SuperAdminOrganizations = Loadable(
  lazy(() => import("./pages/superadmin/Organizations"))
);
const SuperAdminAddOrganization = Loadable(
  lazy(() => import("./pages/superadmin/Organizations/Add"))
);
const SuperAdminShowOrganization = Loadable(
  lazy(() => import("./pages/superadmin/Organizations/Show"))
);
const SuperAdminOrganizationUsers = Loadable(
  lazy(() => import("./pages/superadmin/Organizations/Show/Users"))
);
const SuperAdminUsers = Loadable(
  lazy(() => import("./pages/superadmin/Admins"))
);
const SuperAdminAddUser = Loadable(
  lazy(() => import("./pages/superadmin/Admins/Add"))
);
const SuperAdminGlobalConfiguration = Loadable(
  lazy(() => import("./pages/superadmin/Configuration"))
);
const SuperAdminOrganizationPatientProfiles = Loadable(
  lazy(() => import("./pages/superadmin/Organizations/Show/PatientProfiles"))
);
const SuperAdminOrganizationPatientProfileShow = Loadable(
  lazy(
    () => import("./pages/superadmin/Organizations/Show/PatientProfiles/Show")
  )
);

const routes: RouteObject[] = [
  {
    path: "*",
    element: (
      <RequireUser allowedUserTypes={[UserType.NORMAL, UserType.ORG_ADMIN]} />
    ),
    children: [
      {
        index: true,
        element: <PatientProfiles />,
      },
    ],
  },
  {
    path: "/login",
    element: <SignInPage />,
  },
  {
    path: "/patient-profiles",
    element: (
      <RequireUser allowedUserTypes={[UserType.NORMAL, UserType.ORG_ADMIN]} />
    ),
    children: [
      {
        index: true,
        element: <PatientProfiles />,
      },
      {
        path: "add",
        element: <AddPatientProfile />,
      },
      {
        path: ":id",
        element: <ShowPatientProfile />,
      },
      {
        path: ":patientProfileId/history/:calculationId",
        element: <ShowPatientProfilePreviousCalculation />,
      },
    ],
  },
  {
    path: "/users",
    element: (
      <RequireUser allowedUserTypes={[UserType.NORMAL, UserType.ORG_ADMIN]} />
    ),
    children: [
      {
        index: true,
        element: <Users />,
      },
      {
        path: "add",
        element: <RequireUser outlet allowedUserTypes={[UserType.ORG_ADMIN]} />,
        children: [
          {
            index: true,
            element: <AddUser />,
          },
        ],
      },
      {
        path: ":id",
        element: <RequireUser outlet allowedUserTypes={[UserType.ORG_ADMIN]} />,
        children: [
          {
            index: true,
            element: <ShowUser />,
          },
        ],
      },
    ],
  },
  {
    path: "/settings",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <UpdateUserSettings />,
      },
    ],
  },
  {
    path: "/configuration",
    element: <RequireUser allowedUserTypes={[UserType.ORG_ADMIN]} />,
    children: [
      {
        index: true,
        element: <OrganizationConfiguration />,
      },
    ],
  },
  {
    path: "/admin",
    element: <RequireUser allowedUserTypes={[UserType.SUPER_ADMIN]} />,
    children: [
      {
        path: "organizations",
        element: <SuperAdminOrganizations />,
      },
      {
        path: "organizations/add",
        element: <SuperAdminAddOrganization />,
      },
      {
        path: "organizations/:id",
        element: <SuperAdminShowOrganization />,
      },
      {
        path: "organizations/:id/users",
        element: <SuperAdminOrganizationUsers />,
      },
      {
        path: "organizations/:id/patient-profiles",
        element: <SuperAdminOrganizationPatientProfiles />,
      },
      {
        path: "organizations/:id/patient-profiles/:patientProfileId",
        element: <SuperAdminOrganizationPatientProfileShow />,
      },
      {
        path: "users",
        element: <SuperAdminUsers />,
      },
      {
        path: "users/add",
        element: <SuperAdminAddUser />,
      },
      {
        path: "configuration",
        element: <SuperAdminGlobalConfiguration />,
      },
    ],
  },
];

export default routes;
